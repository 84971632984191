import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => {
    return (
       <div>

       </div>
    );
};

export default HomePage;